<template>
    <div class="FormBox">
        <van-form  @submit="onSubmit" :show-error='false' ref="Form" :scroll-to-error='true'>
            <div class="EventBox" >
                <h4 class="FormBoxTitle">仪器设备预约</h4>
                <van-cell-group>
                    <van-field @input="$emit('ModelChange',TestValue)" required v-model="TestValue.LA_PersonLiable"  label="使用责任人"  placeholder="仪器使用责任人"  :rules="[{ required: true, message: '仪器使用责任人' }]" />
                    <van-field @input="$emit('ModelChange',TestValue)" required v-model="TestValue.LA_PersonLiablePost"  label="责任人职务/学号" placeholder="使用人职务"  :rules="[{ required: true, message: '使用人职务' }]" />
                    <van-field required :value="TestValue.LA_EquipmentUsageTime | Datetime('yyyy-mm-dd')"  readonly @click="UseTime = true" clickable label="设备使用时间" placeholder="设备使用时间"  :rules="[{ required: true, message: '使用时间',trigger:'onChange' }]" />
                </van-cell-group>
                <div class="BoxRow">
                    <h4>设备名称、数量</h4>
                    <van-field @input="$emit('ModelChange',TestValue)" v-model="TestValue.LA_EquipmentNum"  placeholder="设备名称、数量" 
                    class=""
                     type="textarea"
                     autosize
                     rows="4"
                    :rules="[{ required: true, message: '设备名称、数量' }]"
                    />
                </div>
                <div class="BoxRow tpsBox">
                    <h4 class="Tips">设备预约说明</h4>
                    <p>1)、实验室使用单位可要求实验室帮助准备实验所需且合乎规定的仪器设备</p>
                    <p>2)、大型、贵重或对安全操作要求较高的设备， 应由使用单位自行办理，实验室管理人员应与协助，但不得代办</p>
                </div>
            </div>
        </van-form>
        <div class="InfButtom van-hairline--top">
            <van-button  type="primary" block @click="NextClick">下一步</van-button>
        </div>
        <van-popup v-model="UseTime" position="bottom">
            <van-datetime-picker  :formatter="formatter"  @cancel="UseTime = false"  @confirm="UseTimeConfirm" v-model="currentDate" type="date" title="实验室使用时间"/>
        </van-popup>
    </div>
</template>
<script>
import { Form,CellGroup,Field,Button,Icon ,Popup,DatetimePicker,} from 'vant';
export default {
    model:{
        prop: 'TestValue',
        event: 'ModelChange'
    },
    props:{
        TestValue:{
            type:Object
        }
    },
    components:{
      
        [Form.name]: Form,
        [CellGroup.name]: CellGroup,
        [Field.name]: Field,
        [Button.name]: Button,
        [Icon.name]: Icon,
        [Popup.name]: Popup,
        [DatetimePicker.name]: DatetimePicker,
    },
    data() {
        return {
           value:'',
           UseTime:false,
           currentDate:new Date(),
        }
    },
    methods:{
        formatter(type, val) {
            if (type === 'year') {
                return `${val}年`;
            } else if (type === 'month') {
                return `${val}月`;
            }else if (type === 'day') {
                return `${val}日`;
            }else if (type === 'hour') {
                return `${val}时`;
            }else if (type === 'minute') {
                return `${val}分`;
            }
            return val;
        },
        UseTimeConfirm(time){
            this.TestValue.LA_EquipmentUsageTime = time.toLocaleDateString()
            this.$emit('ModelChange',this.TestValue)
            this.UseTime = false;
        },
         onSubmit(){
             
            this.$router.push({path:'/makeAppointment/laboratoryReagent',query:{fromPath:this.$route.query.fromPath}})
        },
        NextClick(){
           this.$refs.Form.submit()
        }
    }
}
</script>

<style lang="scss" scoped>
.FormBox {
    flex: 1;
    display: flex;
    height: calc(100vh - 46px);
    flex-direction: column;
    .van-form {
        flex: 1;
        padding-bottom: 10px;
        overflow-y: auto;
    }
    .EventBox {
        padding-top: 10px;
        background: #f2f2f2;
        .FormBoxTitle {
            font-size: 14px;
            background: #fff;
            padding: 8px 14px;
            color: #333;
        }  

        .BoxRow {
            background: #fff;
            margin-top: 8px;
            h4 {
                font-size: 14px;
                color: #333;
                padding: 0px 14px;
                padding-top: 10px;
            }
            /deep/ .van-field {
                padding: 10px 10px;
            }
            /deep/ .van-field__body {
                border: 1px solid #e6e6e6;
                border-radius: 5px;
                padding:5px;
            }
            p {
                font-size: 13px;
                color: #ee0a24;
                padding: 0 14px;
                line-height: 22px;
            }
            .Tips {
                padding-bottom: 10px;
            }
          
        }
        .tpsBox {
            padding-bottom: 10px;
        }
    }
}
.InfButtom {
    padding: 10px 14px;
    background-color: #fff;

    .van-button {
        line-height: 38px;
        height: 38px;
    }
}
</style>